import React, { Component } from 'react';
import TrafficTypeItem from '../TrafficTypeItem/TrafficTypeItem';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Loading from '../../../component/UI/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './RequestModal.css'

class RequestModal extends Component {

    _isMounted = false;

    state = {
        dataLoaded: false,
        submitted: false,
        clicked: false,
        campaignData: '',
        approvalQuestions: '',
        selectedTrafficType: '',
        ownCreative: false
    }

    componentDidMount() {

        this._isMounted = true;

        if (this.props.data.details.status === 'By Request') {

            const offerId = this.props.data.campaign_id;
            const authtoken = localStorage.getItem('mb-auth-token');

            fetch(`${process.env.REACT_APP_API_URL}/campaign/${offerId}/requestApproval`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authtoken
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result["success"] === true) {
                            if (this._isMounted) {
                                this.setState({
                                    dataLoaded: true,
                                    campaignData: result.approval_info,
                                    approvalQuestions: result.approval_questions
                                });
                            }
                        } else if (result["success"] === false) {
                            this.props.showSystemMessage("error", "There was an error, please try again.");
                            if (this._isMounted) {
                                this.setState({
                                    dataLoaded: true
                                });
                            }
                        } else {
                            this.props.showSystemMessage("error", result.errors.message);

                            if (this._isMounted) {
                                this.setState({
                                    dataLoaded: true
                                });
                            }
                        }
                    },
                    (error) => {
                        this.props.showSystemMessage("error", "Connection error.")
                        if (this._isMounted) {
                            this.setState({
                                dataLoaded: true
                            });
                        }
                    }
                )
        } else {
            if (this._isMounted) {
                this.setState({
                    dataLoaded: true
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTrafficTypeOptions = () => {
        const { allowed_traffic_types } = this.props.data;
        let rows = [];

        for (var key in allowed_traffic_types) {
            rows.push(<TrafficTypeItem key={key} trafficType={key} allowed={allowed_traffic_types[key]} type="option" />)
        }

        return rows;
    }

    handleTrafficType = (event) => {
        this.setState({
            selectedTrafficType: event.target.value
        });
    }

    handleChangeCreative = (event) => { 
        this.setState({
            ...this.state,
            ownCreative: event.target.value
        })
    }

    handleFileChange = (event) => {        
        this.setState({ creativeFile: event.target.files[0] })
    }

    handleSubmit = (e) => {

        this.setState({
            clicked: true
        });

        e.preventDefault();

        if (this.props.data.details.status === 'By Request') {

            const offerId = this.props.data.campaign_id;
            const authtoken = localStorage.getItem('mb-auth-token');

            let custom1 = (e.target.custom_answer_1) ? e.target.custom_answer_1.value : "";
            let custom2 = (e.target.custom_answer_2) ? e.target.custom_answer_2.value : "";
            let custom3 = (e.target.custom_answer_3) ? e.target.custom_answer_3.value : "";

            let source1 = (e.target.form_source_answer_1) ? e.target.form_source_answer_1.value : "";
            let source2 = (e.target.form_source_answer_2) ? e.target.form_source_answer_2.value : "";
            let source3 = (e.target.form_source_answer_3) ? e.target.form_source_answer_3.value : "";            

            const requestData = {
                "estimatedLeads": e.target.estimated_leads.value,
                "trafficType": e.target.traffic_type.value,
                "description": e.target.description.value,
                "custom1": custom1,
                "custom2": custom2,
                "custom3": custom3,
                "creative": e.target.creative_choice.value,
                "source1": source1,
                "source2": source2,
                "source3": source3
            }            

            if (isNaN(requestData.estimatedLeads)){
                this.props.showSystemMessage("error", "Number of leads you will send must be a numeric value.");
                this.setState({
                    clicked: false
                });
                return;
            }

            let formData = new FormData();

            for (var key in requestData) {
                formData.append(key, requestData[key]);
            }

            if (this.state.creativeFile){
                formData.append('creativeFile', this.state.creativeFile, `${this.props.affiliateId}_${offerId}`)
            }

            fetch(`${process.env.REACT_APP_API_URL}/campaign/${offerId}/requestApproval`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'x-app-token': authtoken
                },
                body: formData
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result["success"] === true) {
                            // The request has been made, update the state with the message.
                            if (this._isMounted) {
                                this.setState({
                                    submitted: true
                                });
                            }
                            // We not want the main page to load the new affiliate campaign status.
                            this.props.updateCampaignData()
                        } else if (result["success"] === false) {
                            this.setState({
                                clicked: false
                            });
                            this.props.showSystemMessage("error", result.errors.message);
                        } else {
                            this.setState({
                                clicked: false
                            });
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    },
                    (error) => {
                        this.props.showSystemMessage("error", "Connection error.")
                    }
                )
        }

    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.modalStatus} toggle={this.props.toggleModal} size='lg'>
                    <ModalHeader toggle={this.props.toggleModal}>Campaign Application</ModalHeader>
                    <ModalBody>
                        <div className="mb-2">
                            Use this screen to request access to this campaign.<br></br>
                            <u>NOTE:</u> Please answer all questions completely and accurately or your request will be denied.<br></br>
                        </div>
                        <hr></hr>
                        {this.state.dataLoaded ?
                            <React.Fragment>
                                {!this.state.submitted ?
                                    <React.Fragment>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.campaignData.approval_preface }} />
                                        <hr></hr>
                                        <form
                                            className="byrequest-form"
                                            onSubmit={this.handleSubmit}>
                                            {this.state.approvalQuestions.question1 &&
                                                <div className="form-group">
                                                    <label for="custom_answer_1">{this.state.approvalQuestions.question1}</label>
                                                    <input type="text" className="form-control" name="custom_answer_1" required></input>
                                                </div>
                                            }
                                            {this.state.approvalQuestions.question2 &&
                                                <div className="form-group">
                                                    <label for="custom_answer_2">{this.state.approvalQuestions.question2}</label>
                                                    <input type="text" className="form-control" name="custom_answer_2" required></input>
                                                </div>
                                            }
                                            {this.state.approvalQuestions.question3 &&
                                                <div className="form-group">
                                                    <label for="custom_answer_3">{this.state.approvalQuestions.question3}</label>
                                                    <input type="text" className="form-control" name="custom_answer_3" required></input>
                                                </div>
                                            }
                                            <div className="form-group">
                                                <label for="estimated_leads">How many leads per day will you send?</label>
                                                <input type="text" className="form-control short" name="estimated_leads" size="10" maxLength="10" required></input>
                                            </div>
                                            <div className="form-group">
                                                <label for="creative_choice">Will you use existing campaign creative or make your own?</label>
                                                <select className="custom-select" name="creative_choice" required onChange={this.handleChangeCreative}>
                                                    <option value=""></option>
                                                    <option value="E">Existing creative</option>
                                                    <option value="M">Make my own</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.ownCreative === 'M' &&
                                                <div className="form-group">
                                                    <label for="upload_creative">Please upload an image of your creative. (20mb limit)</label>
                                                    <input name="upload_creative" type="file" accept="image/*" className="form-control" onChange={this.handleFileChange} required />
                                                </div>
                                            }
                                            <div className="form-group">
                                                <label for="traffic_type">What type of traffic will you use? Traffic types not listed here are NOT ALLOWED.</label>
                                                <select className="custom-select" name="traffic_type" onChange={this.handleTrafficType} value={this.state.selectedTrafficType} required>
                                                    <option value=""></option>
                                                    {this.getTrafficTypeOptions()}
                                                </select>
                                            </div>
                                            {this.state.selectedTrafficType === 'R' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">Which search engines will you use?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">Will you direct link or use a custom landing page?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="Direct link">Direct link</option>
                                                            <option value="Custom landing page">Custom landing page</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">If you are using a custom landing page, what is its URL?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_3" required></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'L' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">Which social sources will you use?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">Will you use paid ads or a page/group?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="Paid ads">Paid ads</option>
                                                            <option value="Profile/fanpage traffic">Profile/fanpage traffic</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">If you are a using page/group, what is its URL?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_3" required></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'S' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">What is the URL of the site you will use?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">Will you use banners or text ads?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="Banners">Banners</option>
                                                            <option value="Text ads">Text ads</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">How does traffic get to your website?</label>
                                                        <input type="text" className="form-control long" name="form_source_answer_3" required></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'C' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">What traffic network will you use?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">Will you direct link or use a custom landing page?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="Direct link">Direct link</option>
                                                            <option value="Custom landing page">Custom landing page</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">If you are using a custom landing page, what is its URL?</label>
                                                        <input type="text" className="form-control long" name="form_source_answer_3" required></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'E' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">How do you generate your e-mail list?</label>
                                                        <input type="text" className="form-control long" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">What type of e-mail traffic do you send?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="Auto-responder">Auto-responder</option>
                                                            <option value="Solo e-mail blast">Solo e-mail blast</option>
                                                            <option value="Newsletter ad">Newsletter ad</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">What is your list demographic?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_3" required></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'I' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">What incentive do you provide surfers?</label>
                                                        <input type="text" className="form-control long" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">Is this an offerwall or incentive site?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="Offerwall">Offerwall</option>
                                                            <option value="Incentive site">Incentive site</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">What is the URL of your incentive site?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_3" required></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'M' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">What mobile traffic network will you use?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">What kind of mobile ads will you run?</label>
                                                        <select className="custom-select" name="form_source_answer_2" required>
                                                            <option value=""></option>
                                                            <option value="In app ads">In app ads</option>
                                                            <option value="Mobile pops">Mobile pops</option>
                                                            <option value="Mobile display">Mobile display</option>
                                                            <option value="Mobile video ads">Mobile video ads</option>
                                                            <option value="Abstract ads">Abstract ads</option>
                                                            <option value="Push notifications">Push notifications</option>
                                                            <option value="AppWalls">AppWalls</option>
                                                            <option value="Overlay ads">Overlay ads</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">If you will use a custom LP, provide the link.</label>
                                                        <input type="text" className="form-control" name="form_source_answer_3"></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            {this.state.selectedTrafficType === 'N' &&
                                                <React.Fragment>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_1">What native traffic network will you use?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_1" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_2">What kind of native ads will you run?</label>
                                                        <input type="text" className="form-control" name="form_source_answer_2" required></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="form_source_answer_3">If you will use a custom LP, provide the link.</label>
                                                        <input type="text" className="form-control" name="form_source_answer_3"></input>
                                                    </div>
                                                </React.Fragment>
                                            }
                                            <div className="form-group">
                                                <label for="description">Fully describe how you plan to run this campaign:</label>
                                                <textarea className="form-control" name="description" required></textarea>
                                            </div>
                                            {!this.state.clicked ?
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                :
                                                <button className="btn btn-primary" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                            }
                                        </form>
                                    </React.Fragment>
                                    :
                                    <div class="alert alert-success">
                                        You have successfully submitted your request!
                                    </div>
                                }
                            </React.Fragment>
                            :
                            <Loading />
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default RequestModal;