import React, { Component } from 'react';

class CreativeEmailHTML extends Component {

    _isMounted = false;

    state = {
        isCreativeSelected: false,
        selectedCreativeId: '',
        currentCreativeElements: [],
        filesNotFound: true,
        dataLoaded: false
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            this.props.creatives.map(async eHTML => {
                let result = await this.getCreativeEmailHTMLData(eHTML);
                if (result["success"] === true) {
                    let currentCreativeElements = [...this.state.currentCreativeElements];
                    currentCreativeElements.push({
                        "tracking_link": result["tracking-link"],
                        "creative_id": eHTML.creative_id
                    });
                    this.setState({ currentCreativeElements, filesNotFound: false });
                }
            });
            this.setState({ dataLoaded: true });
        } catch (error) {
            this.props.showSystemMessage('error', error.message);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.currentCreativeElements !== this.state.currentCreativeElements;
    }

    creativeSelectedHandler = (event) => {
        this.setState({ isCreativeSelected: true, selectedCreativeId: [event.currentTarget.value] }, () => {
            this.props.creativeSelected(this.state);
        });
    }
    // Probably should use async/await to make the array length checking working again
    getCreativeEmailHTMLData = async (emailHTML) => {
        const authtoken = localStorage.getItem('mb-auth-token');

        let response = await fetch(`${process.env.REACT_APP_API_URL}/trackinglink`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                campaign_id: this.props.campaign_id,
                traffic_type: this.props.selectedTrafficType,
                creative_id: emailHTML.creative_id
            })
        });

        let result = await response.json();
        return result;
    }

    render() {

        const creativeEmails = (this.state.currentCreativeElements.map(email => {
            return (
                <div className="card my-2" key={email.creative_id}>
                    <div className="card-body d-flex">
                        <div className="flex-1 d-flex align-items-center p-3" style={{overflow: 'scroll'}}>
                            <div dangerouslySetInnerHTML={{ __html: email.tracking_link }}>
                            </div>
                        </div>
                        <div className="d-flex align-items-center p-3">
                            {this.props.mode.mode === 'tracking' &&
                                <button className="creativeSelect btn btn-outline-dark" value={email.creative_id} onClick={this.creativeSelectedHandler}>Select</button >
                            }
                        </div>
                    </div>
                </div>
            );
        }));

        return (
            <React.Fragment>
                <div className="modal-header" >
                    <div className="modal-title">{this.props.mode.mode === 'tracking' ? <React.Fragment>Select an Email</React.Fragment> : <React.Fragment>Email Creatives</React.Fragment>}</div>
                    <button type="button" className="close" onClick={this.props.modalClosed}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    {(this.state.currentCreativeElements.length > 0 && this.state.dataLoaded) ? creativeEmails : <div className="alert-danger p-2">Creative files not found.</div>}
                </div>
            </React.Fragment>
        );
    }
}

export default CreativeEmailHTML;