import React, { Component } from 'react';

class CreativeEmailText extends Component {


    _isMounted = false;

    state = {
        isCreativeSelected: false,
        currentCreativeElements: [],
        selectedCreativeId: ''
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.creatives.map(email =>
            this.getCreativeEmailData(email)
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    creativeSelectedHandler = (event) => {
        this.setState({
            isCreativeSelected: true,
            selectedCreativeId: [event.currentTarget.value]
        }, () => {
            this.props.creativeSelected(this.state);
        });
    }

    getCreativeEmailData = (emailText) => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/trackinglink`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                campaign_id: this.props.campaign_id,
                traffic_type: this.props.selectedTrafficType,
                creative_id: emailText.creative_id
            })
        })
            .then(res => res.json())
            .then(result => {
                if (this._isMounted) {
                    if (result["success"] === true) {
                        let currentCreativeElements = [...this.state.currentCreativeElements];

                        currentCreativeElements.push({
                            "tracking_link": result["tracking-link"],
                            "creative_id": emailText.creative_id
                        });

                        this.setState({
                            currentCreativeElements, dataLoaded: true
                        });
                    } else if (result["success"] === false) {
                        if (result.code !== 401) {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                    }
                }
            }).catch(error => {
                this.props.showSystemMessage("error", "connection error.");
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-header" >
                    <div className="modal-title" style={{ fontSize: "18px" }}>Select an Email</div>
                    <button type="button" className="close" onClick={this.props.modalClosed}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    {this.state.currentCreativeElements.map(email => {
                        return (
                            <div className="card my-2" key={email.creative_id}>
                                <div className="card-body d-flex">
                                    <div className="d-flex align-items-center w-100" style={{ fontSize: "17px", fontFamily: "futura-pt", color: "grey" }}>
                                        <div dangerouslySetInnerHTML={{ __html: email.tracking_link }} />
                                    </div>
                                    <div className="d-flex align-items-center p-3">
                                        <button className="creativeSelect btn btn-outline-dark" value={email.creative_id} onClick={this.creativeSelectedHandler} >Select</button >{/* props.value */}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                    }
                </div>
            </React.Fragment>
        );
    }
}


export default CreativeEmailText;